/* eslint-disable id-length, @typescript-eslint/no-use-before-define */
import { DateTime, Duration } from 'luxon'
import { MutationErrorWithTypename } from 'd2/hooks/useMutation'
import {
  compact,
  concat,
  floor,
  head,
  join,
  last,
  padEnd,
  repeat,
  replace,
  round,
  slice,
  split,
  toLower,
  toNumber,
  toUpper,
} from 'lodash-es'
import invariant from 'invariant'
import md5 from 'md5'
import numeral from 'numeral'

const INDEX_ZERO = 0
const INDEX_NEGATIVE_ONE = -1
const INDEX_ONE = 1

const BINARY_KILO = 1024
const THOUSAND = 1000

const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24

export function prettyUrl (url: string): string {
  return replace(url, /^(https?|mailto):\/\//, '')
}

export function base64Encode (unencoded: Buffer): string {
  return Buffer.from(unencoded).toString('base64')
}

export function base64Decode (encoded: string): string {
  return Buffer.from(encoded || '', 'base64').toString('utf8')
}

export function base64UrlEncode (unencoded: Buffer): string {
  const encoded = base64Encode(unencoded)
  return replace(replace(replace(encoded, '+', '-'), '/', '_'), new RegExp('=+$'), '') // eslint-disable-line prefer-regex-literals
}

export function base64UrlDecode (encoded: string): string {
  let encodedStr = replace(replace(encoded, '-', '+'), '_', '/')
  while (encodedStr.length % 4) {
    encodedStr += '='
  }
  return base64Decode(encodedStr)
}
export type DateType = Date | number | string

export const sentenceCase = (string: string): string => {
  const rg = /(^\s*\w|\.\s*\w)/gi
  string = replace(string, /[_-]/g, ' ')
  return replace(string, rg, (toReplace: string): string => toUpper(toReplace),
  )
}

const TRUNCATE_WITH = '...'
const TRUNCATE_WITH_LENGTH: number = TRUNCATE_WITH.length

export function stringToColor (str: string, colors: string[]): string {
  // @ts-expect-error ts doesn't know that modulo means we'll always return a string
  return colors[toNumber(md5(str).replace(/\D/gi, '')[0] ?? 0) % colors.length]
}

export const backgroundUrl = (url: string): string => `url(${url})`

export const capitalize = function (string: string): string {
  return toUpper(string.charAt(INDEX_ZERO)) + string.slice(INDEX_ONE)
}

export const truncateString = (val: string, amount: number): string => // eslint-disable-next-line unicorn/prefer-string-slice
  val && val.length > amount ? `${val.substr(INDEX_ZERO, amount - TRUNCATE_WITH_LENGTH)}...` : val

export function htmlRawSpace (length = 1): string {
  return repeat('\u00A0', length)
}

export const equals = (string1: string, string2: string): boolean => toLower(string1) === toLower(string2)

// Convert the traditional errors Object into a HR Array.
// ts-prune-ignore-next - Used in D1 JS
export const stringifyErrors = function (
  errors: {
    [x: string]: Array<string>
  },
): string[] {
  const array = []

  for (const field in errors) {
    // @ts-expect-error ts doesn't know that errors[field] means we'll always return something
    const fieldErrors: Array<string> | string = errors[field]
    if (Array.isArray(fieldErrors)) {
      for (const fieldError of fieldErrors) {
        array.push(sentenceCase(`${field} ${fieldError}`))
      }
    } else {
      array.push(sentenceCase(`${field} ${fieldErrors}`))
    }
  }

  return array
}

export const stringifyMutationErrors = function (errors: MutationErrorWithTypename[]): string[] {
  return errors.map(({ key, messages }) => {
    if (key) {
      return sentenceCase(`${key} ${messages.join(', ')}`)
    }
    return sentenceCase(messages.join(', '))
  })
}

export const removeFileExtension = (fileName: string): string => fileName.slice(0, Math.max(0, fileName.lastIndexOf('.')))

export const prettyNumber = (number: number | string): string => numeral(number).format('0,0.[000]')

// instead of long numbers, just round to thousands, like '4k'
export function prettyNumberInexact (number: number): string {
  if (number < 1000) {
    // Safari 9.1 and other old browsers do not support Intl, otherwise we would it:
    // return new Intl.NumberFormat().format(number)
    return prettyNumber(number)
  }

  if (number < 1_000_000) {
    if (number < 10_000) {
      return `${(number / 1000).toFixed(1)}k`
    }
    return `${Math.floor(number / 1000)}k` // instead of long numbers, just round to thousands, like '4k'
  }

  if (number < 1_000_000_000) {
    if (number < 10_000_000) {
      return `${(number / 1_000_000).toFixed(1)}M`
    }
    return `${Math.floor(number / 1_000_000)}M` // instead of long numbers, just round to thousands, like '4M'
  }

  return `${(number / 1_000_000_000).toFixed(1)}B` // '1.0B' or '1.1B' because 1 million is a lot and you might want to see the decimal
}

const ZERO = 0 as const
const ONE = 1 as const
const TWO = 2 as const

export const prettyPrice = (number: number, digits: number = TWO): string => {
  const isNegative: boolean = number < ZERO
  number = Math.abs(number)

  const [integerDigits = '', decimalDigits] = split(String(floor(number, digits)), '.', TWO)
  // eslint-disable-next-line unicorn/no-unsafe-regex
  const commaSeparatedIntegerDigits = replace(integerDigits, /(\d)(?=(\d{3})+$)/g, '$1,')
  const formattedNumber = digits === ZERO ? commaSeparatedIntegerDigits : `${commaSeparatedIntegerDigits}.${padEnd(decimalDigits, digits, '0')}`
  return `${isNegative ? '-' : ''}$${formattedNumber}`
}

export const prettyPriceDifference = function (number: number, digits: number = TWO): string {
  const isNegative = number < 0
  return `${isNegative ? '-' : '+'} ${prettyPrice(Math.abs(number), digits)}`
}

export const prettyPercentage = function (number: number, digits: number = ZERO): string {
  let rounded = null
  if (digits === ZERO) {
    rounded = Math.round(number)
  } else {
    const splitNumbers = number.toString().split('.')
    rounded = splitNumbers.length === ONE || Number.parseInt(splitNumbers[ONE] ?? '0', 10) === ZERO ? number : number.toFixed(digits)
  }
  return `${rounded}%`
}

export const toJSDate = (date: DateType): Date => new Date(date)

// @example Monday, June 3, 2019
export const prettyLongDate = (date: DateType): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromJSDate(toJSDate(date)).toFormat('cccc, MMMM dd, yyyy')
}

// @example Monday, June 3, 2019
export const prettyLongDateUTC = (date: DateType): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromJSDate(toJSDate(date)).toUTC()
    .toFormat('cccc, MMMM dd, yyyy')
}

export const prettyDate = (date: DateType): string => DateTime.fromJSDate(toJSDate(date)).toFormat('D')
export const prettyDateLuxon = (date: Scalar$DateTime): string => DateTime.fromISO(date, { setZone: true }).setZone('local', { keepLocalTime: true })
  .toFormat('D')
export const prettyDateUTC = (date: DateType): string => DateTime.fromJSDate(toJSDate(date)).toUTC()
  .toFormat('D')

export const prettyDateAndTime = (date: DateType): string => DateTime.fromJSDate(toJSDate(date)).toFormat('D \'at\' h:mma')
export const prettyDateAndTimeUTC = (date: DateType): string => DateTime.fromJSDate(toJSDate(date)).toUTC()
  .toFormat('D \'at\' h:mma')

export const prettyDateUTCToLocal = (date: string): string => DateTime.fromISO(date, { setZone: true }).setZone('local', { keepLocalTime: true })
  .toFormat('D')

export const prettyWordDate = (date: DateType): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromJSDate(toJSDate(date)).toFormat('DDD')
}

export const prettyDuration = function (seconds: number): string {
  const mm: Duration = Duration.fromObject({ seconds })
  return mm.toFormat('hh:mm:ss')
}

export const prettyTrackDuration = (milliseconds: number): string => {
  const mm: Duration = Duration.fromObject({ milliseconds })
  return mm.toFormat('m:ss')
}

export const prettyTime = (date: DateType): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromJSDate(toJSDate(date)).toFormat('hh:mm:ss a')
}

export const prettyMonthYearShortUTC = (date: string): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromISO(date, { setZone: true }).setZone('local', { keepLocalTime: true })
    .toFormat('LLL yyyy')
}
export const prettyMonthYearUTC = (date: string): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromISO(date, { setZone: true }).setZone('local', { keepLocalTime: true })
    .toFormat('MMMM yyyy')
}
export const prettyMonthYearDigitsUTC = (date: string): string => {
  if (!date) {
    throw new Error(`Expected number, string, or date object, but got: ${date}`)
  }
  return DateTime.fromISO(date, { setZone: true }).setZone('local', { keepLocalTime: true })
    .toFormat('MM/yy')
}

export const prettyPhoneNumber = (phoneNumber: string): string => {
  const cleaned: string = replace(`${phoneNumber}`, /\D/g, '')
  // eslint-disable-next-line unicorn/no-unsafe-regex
  const phoneRegex = cleaned.length === 12 ? /^(\d{2})?(\d{3})(\d{3})(\d{4})$/ : /^(\d)?(\d{3})(\d{3})(\d{4})$/
  const match = cleaned.match(phoneRegex)
  if (match) {
    const intlCode: string = match[1] ? `+${match[1]} ` : ''
    return join([
      intlCode,
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
    ], '')
  }
  return phoneNumber
}

export const prettyStorageSize = (bytes: number): string => {
  const kilobytes = 1000
  const megabytes = 1_000_000
  const gigabytes = 1_000_000_000
  const TO_FIXED_DIGITS = 1

  const prettySizeDigits = (number: number): number => Number.parseFloat(number.toFixed(TO_FIXED_DIGITS))

  if (bytes < megabytes) {
    return `${prettySizeDigits(bytes / kilobytes)}KB`
  }
  if (bytes < gigabytes) {
    return `${prettySizeDigits(bytes / megabytes)}MB`
  }

  return `${prettySizeDigits(bytes / gigabytes)}GB`
}

export const readableMS = function (milliseconds: number): string {
  return readableSeconds(milliseconds / THOUSAND)
}

export const joinList = (list: string[]): string => {
  if (list.length === INDEX_ZERO) return ''
  if (list.length === INDEX_ONE) {
    // @ts-expect-error ts doesn't know that list[0] is a string
    return list[0]
  }

  if (list.length === TWO) return `${head(list)!} and ${last(list)!}`

  return join(concat(slice(list, INDEX_ZERO, INDEX_NEGATIVE_ONE), [`and ${last(list)!}`]), ', ')
}

export const readableSeconds = function (seconds: number): string {
  // @ts-expect-error (auto-migrated from flow FixMe)[incompatible-call] parseInt supposed to take a string
  seconds = Number.parseInt(seconds, 10)

  const d = Math.floor(seconds / SECONDS_IN_DAY)
  const h = Math.floor((seconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR)
  const m = Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
  const s = Math.floor(seconds % SECONDS_IN_MINUTE)

  if (d > INDEX_ZERO) {
    return `${d}${pluralizeWord(d, ' day')}`
  } else if (h > INDEX_ZERO) {
    return `${h}${pluralizeWord(h, ' hour')}`
  } else if (m > INDEX_ZERO) {
    return `${m}${pluralizeWord(m, ' minute')}`
  }

  return `${s}${pluralizeWord(s, ' second')}`
}

export const prettyFileSize = function (bytes: number, si?: boolean): string {
  const thresh = si ? THOUSAND : BINARY_KILO
  if (bytes < thresh) return `${bytes} B`
  const units = si
    ? [
      'kB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ]
    : [
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (bytes >= thresh)

  const unit = units[u]
  invariant(unit, 'unit too large? Got %s %s', unit, bytes)
  return `${bytes.toFixed(INDEX_ONE)} ${unit}`
}

export const prettyFileSizeNoSpace = function (bytes: number, si?: boolean): string {
  const thresh = si ? THOUSAND : BINARY_KILO
  if (bytes < thresh) return `${bytes}B`
  const units = si
    ? [
      'kB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ]
    : [
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (bytes >= thresh)

  const unit = units[u]
  invariant(unit, 'unit too large? Got %s %s', unit, bytes)
  return `${bytes.toFixed(INDEX_ONE)}${unit}`
}

export const prettyFileSizeRoundedNoSpace = function (bytes: number, si?: boolean): string {
  const thresh = si ? THOUSAND : BINARY_KILO
  if (bytes < thresh) return `${bytes}B`
  const units = si
    ? [
      'kB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ]
    : [
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (bytes >= thresh)

  return `${Math.round(Number.parseFloat(bytes.toFixed(INDEX_ONE)))}${units[u] ?? ''}`
}

// Use the plural version of the word unless the number provided is 1.
//
// @param number [Number]
// @param singularWord [String]
// @param pluralWord [String, undefined]
//
// @return pluralizedWord [String]
export const pluralizeWord = function (number: number, singularWord: string, pluralWord?: string): string {
  // @ts-expect-error (auto-migrated from flow FixMe)[incompatible-call] parseInt supposed to take a string
  if (Number.parseInt(number, 10) === INDEX_ONE) {
    return singularWord
  }

  return pluralWord ?? `${singularWord}s`
}

export const titleCase = function (string: string): string {
  const rg = /(^|\s)([a-z])/gi
  string = replace(string, /[_-]/g, ' ')
  return replace(string, rg, toUpper)
}

// ts-prune-ignore-next - Used in D1 JS
export const songName = function (filename: string): string {
  const noExtension = filename.replace(/\.\w+$/, '')
  const noDot = noExtension.replace(/\./g, ' ')
  return titleCase(noDot)
}

// Add query string params to a base URI.
// ts-prune-ignore-next - Used in D1 JS
export const compileURI = function (
  base: string,
  params: {
    [x: string]: string
  },
): string {
  const array = []

  for (const k in params) {
    array.push(`${encodeURIComponent(k)}=${encodeURIComponent(
      // @ts-expect-error ts doesn't know `params[k]` is a string
      params[k],
    )}`)
  }

  return `${base}?${array.join('&')}`
}

export const fullName: (b?: string | null, a?: string | null) => string = (firstName, lastName): string => join(compact([firstName, lastName]), ' ')

export const prettyPercentageForChart = (value: number, sumTotal: number): string => {
  const percentage: number = value / sumTotal * 100
  if (percentage > 0 && percentage < 1) return '<1%'
  if (percentage > 99 && percentage < 100) return '99%'
  return prettyPercentage(round(percentage))
}

export const dataDelayForNumDays = (numDays: number): string => `Reported data may vary from final results and can take up to ${numDays} ${pluralizeWord(numDays, 'day')} to populate on your dashboard.`
