import '../vendor/fullstory'
import { FullStory } from '@fullstory/browser'
import ready from '../utils/ready'

export function init () {
  const user = window.current_user
  if (window.FS && user && user.id) {
    const properties = {
      createdAt: user.created_at,
      email: user.email,
      estimatedRevenue: user.estimated_revenue.to_f,
      fullName: user.full_name,
      currentTier: (user.use_override_tier ? user.override_user_tier : user.current_tier) || 1000,
      lifetimeTier: (user.use_override_tier ? user.override_user_tier : user.lifetime_tier) || 1000,
      tierIsEstimate: user.use_override_tier,
      isAdmin: user.is_admin || window.adminLayout,
      organizationId: user.organization_id,
      organizationRole: user.role,
      phone: user.phone,
      userId: user.id,
    }
    const observer = window.current_observer
    if (window.FS && observer && observer.id) {
      properties.observerId = observer.id
      properties.observerOrganizationId = observer.organization_id
    }
    const features = window.enabledFeatures
    features.forEach((feature) => {
      feature = feature.replace(/(_\w)/g, (m) => m[1].toUpperCase())
      properties[`hasFeature${feature}`] = true
    })
    const fullstoryId = observer?.id ? `${observer.id}::${user.id}` : user.id
    properties.displayName = observer?.id ? `${observer.full_name || observer.email} as ${user.full_name || user.email}` : user.full_name || user.email
    FullStory('setIdentity', {
      uid: fullstoryId,
      properties,
    })
  }
}

ready(init)
